import { Component, Input, Output, EventEmitter, SimpleChange } from '@angular/core';

@Component({
    selector: 'KCS-Pagination',
    templateUrl: './pagination.component.html',
})
export class PaginationComponent {

    @Input() totalrows: number;
    @Input() pageelement: number;
    @Input() orderreset: boolean;
    @Input() pageselected: number;
    @Output() outPage = new EventEmitter<{ pagenum: number }>();

    pagesIndex: Array<number>;
    pagenum: number = 1;
    pages: number = 0;
    pageStart: number = 1;
    nextPage: number = 2;
    backPage: number = 1;
    pageEnd: number = 1;
    pageStartSlice: number = 0;
    pageEndSlice: number = 5;
    controllPageNum = false;

    selectPage(pagenum: number) {
        this.pagenum = pagenum;
        this.splitButton();
        this.outPage.emit({ pagenum: this.pagenum });
    }

    splitButton() {
        if (this.pagenum !== this.pageStart) {
            this.backPage = this.pagenum - 1;
        } else {
            this.backPage = 1;
        }
        if (this.pagenum !== this.pageEnd) {
            this.nextPage = this.pagenum + 1;
        } else {
            this.nextPage = this.pageEnd;
        }

        if (this.pagenum >= this.pageStart + 3 && this.pagenum <= this.pageEnd - 3) {
            this.pageStartSlice = this.pagenum - 3;
            this.pageEndSlice = this.pagenum + 2;
        } else {
            if (this.pagenum < this.pageStart + 3) {
                this.pageStartSlice = 0;
                this.pageEndSlice = 5;
            }

            if (this.pagenum > this.pageEnd - 3 && (this.pageEnd - 5) > 0) {
                this.pageStartSlice = this.pageEnd - 5;
                this.pageEndSlice = this.pageEnd;
            }
        }
        this.setPage();
    }



    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes['totalrows']) {
            this.setPageNum();
            this.pages = this.totalrows / this.pageelement;
            this.pageEnd = Math.floor(this.totalrows / this.pageelement);
            if (this.totalrows / this.pageelement - this.pageEnd !== 0) {
                this.pageEnd = this.pageEnd + 1;
            }
            this.controllPage();
            this.splitButton();
        }
        if (changes['orderreset']) {
            this.setPageNum();
            this.splitButton();
        }
    }

    controllPage(): void {
        if (this.pageEnd <= 5) {
            this.controllPageNum = false;
        } else {
            this.controllPageNum = true;
        }
    }

    setPageNum(): void {
        this.pagenum = 1;
        if (this.pageselected !== undefined) {
            this.pagenum = this.pageselected;
        }
    }

    setPage() { this.pagesIndex = this.fillArray().slice(this.pageStartSlice, this.pageEndSlice); }

    fillArray(): any {
        let obj = new Array();
        for (let index = this.pageStart; index < this.pageStart + this.pageEnd; index++) {
            obj.push(index);
        }
        return obj;
    }

}
