<div class="row">
        <!--<div class="col-lg-6">-->
        <span *ngIf="controllPageNum">
                <button type="button" class="btn waves-effect" (click)="selectPage(pageStart)">
                        <i class="material-icons">first_page</i>
                </button>
                <button type="button" class="btn waves-effect" (click)="selectPage(backPage)">
                        <i class="material-icons">chevron_left</i>

                </button>
        </span>
        <!--iterare secondo il numero di elementi-->
        <!--pageelement{{pageelement}} totalrows{{totalrows}} pages{{pages}} pageEnd{{pageEnd}} pagenum{{pagenum}} pageStartSlice{{pageStartSlice}}-->
        <span *ngFor="let page of pagesIndex">
                <button type="button" class="btn waves-effect" (click)="selectPage(page)" [style.background-color]="(page==pagenum)?'#A09E9E':null">
                        <div>{{page}}</div>
                </button>
        </span>
        <span *ngIf="controllPageNum">
                <button type="button" class="btn waves-effect" (click)="selectPage(nextPage)">
                        <i class="material-icons">chevron_right</i>
                </button>
                <button type="button" class="btn waves-effect" (click)="selectPage(pageEnd)">
                        <i class="material-icons">last_page</i>
                </button> {{pagenum}}/{{pageEnd}}
        </span>

        <!--    </div>-->
</div>