import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, finalize } from 'rxjs/operators';
import { ConfigService } from '../portale/_services/config.service';
import { UserInfo } from '../portale/_models/userinfo';
import { Observable } from 'rxjs';

import { NotificationsService } from 'angular2-notifications';
import * as notificationConfig from '../../config/notificationConfig.json';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable({
  providedIn: 'root'
})
export class UtentiService {

  baseUrl = this.configService.sanybeeUrl;
  loading: any;
  authorizated = false;
  public token: string;
  private storage1Observer: any;
  public storage1: any;
  sendCall = 0;
  backCall = 0;

  constructor(
    public http: HttpClient,
    private spinnerService: NgxSpinnerService,
    public configService: ConfigService,
    private notificationsService: NotificationsService,
  ) {

    this.storage1Observer = null;
    this.storage1 = Observable.create((observer: any) => {
      this.storage1Observer = observer;
    });
  }



  getOperatori(limitda:number,limitper:number) {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http.get(this.baseUrl + 'getAbilitazioniOperatoriPaginata/' + curr.usr_username + '/' + portale.pt_id+'?limitda='+limitda+'&limitper='+limitper,
      { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' })
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }), finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getGestori() {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http.get(this.baseUrl + 'getAbilitazioniGestori/' + curr.usr_username + '/' + portale.pt_id,
      { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' })
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }), finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getAbilitazioniLocale(locid: number, operatore: string,limitda:number,limitper:number) {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http.get(this.baseUrl + 'getAbilitazioniLocale/' + curr.usr_username + '/' + portale.pt_id + '/' + locid + '/' + operatore + '/?limitda='+limitda+'&limitper='+limitper,
      { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' }).pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }), finalize(() => {
          this.dismissLoader();
        })
      );
  }

  salvaAbilitazioniLocale(list: any) {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http.post(this.baseUrl + 'salvaAbilitazioniLocale/' + curr.usr_username + '/' + portale.pt_id, list,
      { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' })
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }), finalize(() => {
          this.dismissLoader();
        })
      );
  }

  salvaAbilitazioniImmobile(list: any, immid: number) {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http.post(this.baseUrl + 'salvaAbilitazioniImmobile/' + curr.usr_username + '/' + portale.pt_id + '/' + immid, list,
      { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' })
      .pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }), finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getAbilitazioniImmobile(immid: number, operatore: string,limitda:number,limitper:number) {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http.get(this.baseUrl + 'getAbilitazioniImmobile/' + curr.usr_username + '/' + portale.pt_id + '/' + immid + '/' + operatore + '/?limitda='+limitda+'&limitper='+limitper,
      { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' }).pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }), finalize(() => {
          this.dismissLoader();
        })
      );
  }

  getUtente(usr_username: string) {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http.get(this.baseUrl + 'getUtente/' + curr.usr_username + '/' + portale.pt_id + '/' + usr_username + '/',
      { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' }).pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }), finalize(() => {
          this.dismissLoader();
        })
      );
  }

  deleteUsr(usr_username: string) {
    this.presentLoader();
    let curr: UserInfo;
    curr = JSON.parse(localStorage.getItem('currentUser'));
    const portale = JSON.parse(localStorage.getItem('portale'));
    return this.http.delete(this.baseUrl + 'delUtente/' + curr.usr_username + '/' + portale.pt_id + '/' + usr_username + '/',
      { headers: this.configService.createAuthorizationHeader(curr), observe: 'response' }).pipe(
        map((res: any) => {
          return this.configService.setToken(res);
        }), finalize(() => {
          this.dismissLoader();
        })
      );
  }

  NotificationSuccess(msg: string, success: boolean) {
    if (success) {
      this.notificationsService.success(
        msg,
        (<any>notificationConfig).notificationOperationSuccess.content,
        (<any>notificationConfig).notificationOperationSuccess.options,
      )
    } else {
      this.notificationsService.error(
        msg,
        (<any>notificationConfig).notificationOperationSuccess.content,
        (<any>notificationConfig).notificationOperationSuccess.options,
      )
    }
  }

  setStorage(user: any) {
    this.storage1Observer.next(user);
  }
  changeStorage() {
    this.storage1Observer.next(null);
  }


  presentLoader() {
    this.sendCall++;
    this.spinnerService.show();
  }

  dismissLoader() {
    this.backCall++;
    if (this.backCall == this.sendCall) {
      this.spinnerService.hide();
      this.backCall = 0;
      this.sendCall = 0;
    }
  }


}

